import React, { useState } from 'react';
import PocketBase from 'pocketbase';
import { useNavigate } from 'react-router-dom';
import Message from '../../frontdesk/Arrivals';

const pb = new PocketBase('https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/');

const Signup = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [avatar, setAvatar] = useState(null);
    const [role, setRole] = useState('user');
    const [gdpr, setGdpr] = useState(false);
    const [terms, setTerms] = useState(false);
    const [beta, setBeta] = useState(true);
    const [marketing, setMarketing] = useState(true);
    const navigate = useNavigate();
    const [show, setShow] = useState(false);

    const handleSignup = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('username', username);
        formData.append('email', email);
        formData.append('password', password);
        formData.append('passwordConfirm', passwordConfirm);
        formData.append('role', 'user');
        formData.append('gdpr', gdpr);
        formData.append('terms', terms);
        formData.append('beta', beta);
        formData.append('marketing', marketing);
        if (avatar) {
            formData.append('avatar', avatar);
        }

        const record = await pb.collection('users').create(formData);
        await pb.collection('users').requestVerification(email);
        navigate('/activate');
    };

    const handleAvatarChange = (e) => {
        setAvatar(e.target.files[0]);
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-footer-link py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8 bg-white/95 backdrop-blur-sm rounded-xl shadow-2xl p-8">
                <div>
                    <h1 className="mt-6 text-center text-3xl font-extrabold text-red-900">
                        Create your account
                    </h1>
                    <p className="mt-2 text-center text-sm text-red-600">
                        Join our community of Pokemon TCG players
                    </p>
                </div>

                <form onSubmit={handleSignup} className="mt-8 space-y-6">
                    <div className="rounded-md -space-y-px">
                        <div className="mb-6">
                            <label className="text-sm font-medium text-red-700">
                                Username *
                            </label>
                            <input
                                className="mt-1 appearance-none relative block w-full px-3 py-2 border border-red-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-red-500 focus:z-10 sm:text-sm"
                                type="text"
                                required
                                placeholder="Username"
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>

                        <div className="mb-6">
                            <label className="text-sm font-medium text-red-700">
                                Email *
                            </label>
                            <input
                                className="mt-1 appearance-none relative block w-full px-3 py-2 border border-red-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-red-500 focus:z-10 sm:text-sm"
                                type="email"
                                required
                                placeholder="Email"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>

                        <div className="mb-6">
                            <label className="text-sm font-medium text-red-700">
                                Password *
                            </label>
                            <input
                                className="mt-1 appearance-none relative block w-full px-3 py-2 border border-red-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-red-500 focus:z-10 sm:text-sm"
                                type="password"
                                required
                                placeholder="••••••••"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>

                        <div className="mb-6">
                            <label className="text-sm font-medium text-red-700">
                                Confirm Password *
                            </label>
                            <input
                                className="mt-1 appearance-none relative block w-full px-3 py-2 border border-red-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-red-500 focus:z-10 sm:text-sm"
                                type="password"
                                required
                                placeholder="••••••••"
                                onChange={(e) => setPasswordConfirm(e.target.value)}
                            />
                        </div>

                        <div className="mb-6">
                            <label className="text-sm font-medium text-red-700">
                                Avatar
                            </label>
                            <input
                                className="mt-1 appearance-none relative block w-full px-3 py-2 border border-red-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-red-500 focus:z-10 sm:text-sm"
                                type="file"
                                onChange={handleAvatarChange}
                            />
                        </div>
                    </div>

                    <div className="space-y-4">
                        <div className="flex items-center space-x-3">
                            <input
                                type="checkbox"
                                className="h-4 w-4 text-red-600 focus:ring-red-500 border-red-300 rounded"
                                onChange={(e) => setMarketing(e.target.checked)}
                                defaultChecked={marketing}
                            />
                            <div className="flex items-center">
                                <label className="text-sm text-red-700">Marketing</label>
                                <div className="ml-2">
                                    <Message text="Exclusive news and discounts." />
                                </div>
                            </div>
                        </div>

                        <div className="flex items-center space-x-3">
                            <input
                                type="checkbox"
                                className="h-4 w-4 text-red-600 focus:ring-red-500 border-red-300 rounded"
                                defaultChecked={beta}
                                disabled
                            />
                            <div className="flex items-center">
                                <label className="text-sm text-red-700">Beta *</label>
                                <div className="ml-2">
                                    <Message text="Platform is subject to change." />
                                </div>
                            </div>
                        </div>

                        <div className="flex items-center space-x-3">
                            <input
                                type="checkbox"
                                className="h-4 w-4 text-red-600 focus:ring-red-500 border-red-300 rounded"
                                onChange={(e) => setGdpr(e.target.checked)}
                                required
                            />
                            <div className="flex items-center">
                                <label className="text-sm text-red-700">GDPR *</label>
                                <a href="https://www.pylar.org/user/privacy"
                                    className="ml-2 text-red-600 hover:text-red-500 text-sm"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    Read more
                                </a>
                            </div>
                        </div>

                        <div className="flex items-center space-x-3">
                            <input
                                type="checkbox"
                                className="h-4 w-4 text-red-600 focus:ring-red-500 border-red-300 rounded"
                                onChange={(e) => setTerms(e.target.checked)}
                                required
                            />
                            <div className="flex items-center">
                                <label className="text-sm text-red-700">Terms *</label>
                                <a href="https://www.pencil.works/terms"
                                    className="ml-2 text-red-600 hover:text-red-500 text-sm"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    Read more
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center justify-between pt-4">
                        <button
                            type="submit"
                            disabled={!gdpr || !terms || !beta}
                            className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white ${(!gdpr || !terms || !beta)
                                ? 'bg-red-400 cursor-not-allowed'
                                : 'bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'}`}
                        >
                            Sign up
                        </button>
                    </div>

                    <div className="text-center mt-4">
                        <a href="/login"
                            className="font-medium text-red-600 hover:text-red-500 text-sm">
                            Already have an account? Sign in
                        </a>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Signup;