import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Landing = () => {
    const [latestSet, setLatestSet] = useState(null);
    const [featuredCards, setFeaturedCards] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [stats, setStats] = useState({ sets: 0, cards: 0 });

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch latest set
                const setsResponse = await axios.get('https://api.pokemontcg.io/v2/sets', {
                    headers: { 'X-Api-Key': process.env.REACT_APP_POKEMON_TCG_API_KEY },
                    params: { orderBy: '-releaseDate', pageSize: 1 }
                });

                const latestSetData = setsResponse.data.data[0];
                setLatestSet(latestSetData);

                // Fetch last 8 cards from latest set (sorted by number in reverse order)
                const cardsResponse = await axios.get('https://api.pokemontcg.io/v2/cards', {
                    headers: { 'X-Api-Key': process.env.REACT_APP_POKEMON_TCG_API_KEY },
                    params: {
                        q: `set.id:${latestSetData.id}`,
                        orderBy: '-number',
                        pageSize: 8
                    }
                });

                setFeaturedCards(cardsResponse.data.data);
                setStats({
                    sets: setsResponse.data.totalCount,
                    cards: cardsResponse.data.totalCount
                });
                setLoading(false);
            } catch (err) {
                setError('Failed to load data');
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) return (
        <div className="min-h-screen flex items-center justify-center">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-white"></div>
        </div>
    );

    if (error) return (
        <div className="min-h-screen flex items-center justify-center text-white">
            <p>{error}</p>
        </div>
    );

    return (
        <div className="min-h-screen bg-bg-footer-title">
            {/* Hero Section with Latest Set */}
            <div className="container mx-auto px-4 py-12">
                <div className="text-center text-white mb-12">
                    {/* <h1 className="text-6xl font-bold mb-4">DECK MAKER</h1>
                    <p className="text-2xl mb-8">Explore the World of Pokemon TCG</p> */}
                    {latestSet && (
                        <div className="bg-footer-bg rounded-lg p-6 mb-8">
                            <h2 className="text-3xl font-bold mb-4">Latest Set: {latestSet.name}</h2>
                            <img src={latestSet.images.logo} alt={latestSet.name} className="h-24 mx-auto mb-4" />
                            <p className="text-xl">Released: {new Date(latestSet.releaseDate).toLocaleDateString()}</p>
                        </div>
                    )}
                </div>

                {/* Featured Cards Grid */}
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-12">
                    {featuredCards.map(card => (
                        <div key={card.id} className="transform hover:scale-105 transition-transform duration-200">
                            <img src={card.images.small} alt={card.name} className="rounded-lg shadow-lg" />
                        </div>
                    ))}
                </div>

                {/* Stats Section */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 text-white text-center">
                    <div className="bg-white/10 backdrop-blur-sm rounded-lg p-6">
                        <h3 className="text-4xl font-bold mb-2">{stats.sets}</h3>
                        <p className="text-xl">Total Sets Available</p>
                    </div>
                    <div className="bg-white/10 backdrop-blur-sm rounded-lg p-6">
                        <h3 className="text-4xl font-bold mb-2">{stats.cards}</h3>
                        <p className="text-xl">Cards to Explore</p>
                    </div>
                </div>

                {/* CTA Section */}
                <div className="text-center mt-12">
                    <button
                        onClick={() => window.location.href = '/signup'}
                        className="bg-white text-red-600 px-8 py-4 rounded-lg text-xl font-bold hover:bg-gray-100 transition-colors duration-200"
                    >
                        Start Your Collection
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Landing;