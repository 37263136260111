import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PocketBase from 'pocketbase';
import Signup from '../airport/control/gate/Signup'; // Assuming Signup.js is in the same directory

const pb = new PocketBase('https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/');

const App = () => {
	const navigate = useNavigate();
	const user = pb.authStore.model;

	useEffect(() => {
		if (user) {
			if (user.verified) {
				navigate('/manage');
			} else {
				navigate('/activate');
			}
		}
	}, [user, navigate]);

	return (
		<div>
			<Signup />
		</div>
	);
};

export default App;