import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import PocketBase from 'pocketbase';

const pb = new PocketBase('https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/');

const FrontDesk = () => {
	const token = localStorage.getItem('token');
	const userRole = pb.authStore.model ? pb.authStore.model.role : null;
	const navigate = useNavigate();
	const user = pb.authStore.model;

	const handleLogout = () => {
		pb.authStore.clear();
		localStorage.removeItem('token');
		localStorage.removeItem('userId');
		localStorage.removeItem('email');
		navigate('/');
	};

	return (
		<nav className="">
			<div className="">
				{token && (
					<>
						{userRole && userRole === 'manager' && (
							<>
								<NavLink to="/sync" className="text-gray-400 hover:text-blue-500" activeClassName="text-blue-500 font-bold">
									Sync
								</NavLink>
								<NavLink to="/search" className="text-gray-400 hover:text-blue-500" activeClassName="text-blue-500 font-bold">
									Search
								</NavLink>
							</>
						)}
					</>
				)}
				{/* user is verified show Manage not, activate */}
				{user && !user.verified && (
					<NavLink to="/activate" className="text-gray-400 hover:text-blue-500" activeClassName="text-blue-500 font-bold">
						Activate
					</NavLink>
				)}
				{!token && (
					<div className="flex justify-between">
						<div className="space-x-20">
							<NavLink to="/"
								className=""
								activeClassName="text-blue-500 font-bold">
								Home
							</NavLink>
							<NavLink to="/login"
								className=""
								activeClassName="text-blue-500 font-bold">
								Login
							</NavLink>
							<NavLink to="/signup"
								className=""
								activeClassName="text-blue-500 font-bold">
								Signup
							</NavLink>
						</div>
					</div>
				)}
				{token && (
					<div className="flex justify-between">
						<div className="space-x-20">
							<div className="">
								<button onClick={handleLogout}
									className="">
									Logout
								</button>
							</div>
						</div>
					</div>
				)}
			</div>
		</nav >
	);
};

export default FrontDesk;