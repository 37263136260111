import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PocketBase from 'pocketbase';
import Sync from '../airport/control/tower/Sync'; // Assuming Sync.js is in the same directory

const pb = new PocketBase('https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/');

const App = () => {
	const navigate = useNavigate();
	const userRole = pb.authStore.model ? pb.authStore.model.role : null;

	useEffect(() => {
		if (userRole !== 'manager') {
			navigate('/manage');
		}
	}, [userRole, navigate]);

	return (
		<div>
			<Sync />
		</div>
	);
};

export default App;