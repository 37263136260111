import React, { useState, useEffect } from 'react';
import PocketBase from 'pocketbase';
import { motion } from 'framer-motion';

const pb = new PocketBase('https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/');

function DeckCreator() {
    const [title, setTitle] = useState('');
    const [selectedCards, setSelectedCards] = useState([]);
    const [stock, setStock] = useState([]);
    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(true);
    const user = pb.authStore.model ? pb.authStore.model.id : null; // Get the user ID
    const [decks] = useState([]);

    useEffect(() => {
        let isCancelled = false;

        const fetchStock = async () => {
            try {
                const stockList = await pb.collection('stock').getFullList({
                    sort: '-created',
                });
                if (!isCancelled) {
                    setStock(stockList);
                }
            } catch (error) {
                if (!isCancelled) {
                    console.error('Error fetching stock:', error);
                }
            }
        };

        const fetchCards = async () => {
            try {
                const cardsList = await pb.collection('cards').getFullList({
                    sort: '-created',
                });
                if (!isCancelled) {
                    setCards(cardsList);
                    setLoading(false);
                }
            } catch (error) {
                if (!isCancelled) {
                    console.error('Error fetching cards:', error);
                }
            }
        }

        fetchStock();
        fetchCards();

        return () => {
            isCancelled = true;
        };
    }, []);

    const createDeck = async () => {
        try {
            await pb.collection('decks').create({
                title,
                cards: selectedCards, // Now you can add multiple cards to a deck
                user,
            });
            setTitle('');
            setSelectedCards([]);
        } catch (error) {
            console.error('Error creating deck:', error);
        }
    }

    if (loading) {
        return (
            <div className="flex flex-col items-center justify-center h-screen">
                <div className="flex space-x-2">
                    {[...Array(3)].map((_, i) => (
                        <motion.div
                            key={i}
                            className="h-4 w-4 bg-black rounded-full"
                            animate={{ y: ["100%", "-100%"] }}
                            transition={{
                                duration: 0.5,
                                repeat: Infinity,
                                repeatType: "reverse",
                                delay: i * 0.2
                            }}
                        />
                    ))}
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-col items-center">
            <motion.div className="flex flex-col items-center" layout>
                <h1
                    className="text-4xl"
                >Create a new deck</h1>
                <input
                    className="text-black"
                    type="text"
                    placeholder="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
                {/* select from stock */}
                <motion.select
                    className="text-black"
                    multiple
                    value={selectedCards}
                    onChange={(e) => setSelectedCards(Array.from(e.target.selectedOptions, option => option.value))}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                >
                    {stock.map((stockItem) => {
                        const card = cards.find(card => card.id === stockItem.card);
                        return (
                            <motion.option key={stockItem.id} value={stockItem.id}>
                                {card ? card.name : 'Card not found'},
                                &nbsp;
                                {card.cardId}
                                &nbsp;
                                {card.rarity}
                                &nbsp;
                                {/* FROM STOCK get me the quantity */}
                                x{stockItem.quantity}
                                {/* now show the  {card.imagesmall} as png */}
                                {/*  <img
                                    src={card.imagesmall}
                                    alt="card"
                                />
                                {card.downloadedimagesmall} */}
                                {/* now show in text the times of the card in decks */}
                                {/* x{stockItem.quantity} */}
                            </motion.option>
                        );
                    })}
                </motion.select>
                <motion.button onClick={createDeck} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} className="text-black">
                    Create deck
                </motion.button>
            </motion.div>
            <motion.div className="decks" layout>
                {decks.map(deck => (
                    <motion.div className="deck" key={deck.id} layoutId={deck.id}>
                        <h2>{deck.title}</h2>
                        <motion.div
                            className="cards"
                            layout
                        >
                            {deck.cards.map(cardId => {
                                const card = cards.find(card => card.id === cardId);
                                return (
                                    <motion.div key={card.id} layoutId={card.id}>
                                        {card.name}
                                    </motion.div>
                                );
                            })}
                        </motion.div>

                    </motion.div>
                ))}
            </motion.div>
        </div>
    );
}


export default DeckCreator;