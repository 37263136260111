import React, { useState } from "react";
import axios from "axios";
import PocketBase from "pocketbase";

const pb = new PocketBase("https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app");

const SyncPricesOnly = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const savePricesToPocketBase = async (card) => {
    try {
      // Extract release date
      const releaseDateStr = card.set.releaseDate;
      const releaseDate = new Date(releaseDateStr);
      const releaseMonth = releaseDate.getMonth() + 1;
      const releaseYear = releaseDate.getFullYear();
      const normalPrices = card.tcgplayer?.prices?.normal || {};
      const reverseHolo = card.tcgplayer?.prices?.reverseHolofoil || {};
      const cmPrices = card.cardmarket?.prices || {};
      const cardEeid =
        card.name +
        "-" +
        card.number +
        "/" +
        card.set.printedTotal +
        "-" +
        card.set.id +
        "-" +
        card.regulationMark;

      const recordData = {
        cardEeid,
        name: card.name,
        set: card.set.name,
        artist: card.artist,
        rarity: card.rarity,
        series: card.set.series,
        releaseDate_month: releaseMonth,
        releaseDate_year: releaseYear,
        tcgplayer_normal_low: normalPrices.low,
        tcgplayer_normal_mid: normalPrices.mid,
        tcgplayer_normal_high: normalPrices.high,
        tcgplayer_normal_market: normalPrices.market,
        tcgplayer_normal_directLow: normalPrices.directLow,
        tcgplayer_reverseHolofoil_low: reverseHolo.low,
        tcgplayer_reverseHolofoil_mid: reverseHolo.mid,
        tcgplayer_reverseHolofoil_high: reverseHolo.high,
        tcgplayer_reverseHolofoil_market: reverseHolo.market,
        tcgplayer_reverseHolofoil_directLow: reverseHolo.directLow,
        cardmarket_averageSellPrice: cmPrices.averageSellPrice,
        cardmarket_lowPrice: cmPrices.lowPrice,
        cardmarket_germanProLow: cmPrices.germanProLow,
        cardmarket_suggestedPrice: cmPrices.suggestedPrice,
        cardmarket_reverseHoloSell: cmPrices.reverseHoloSell,
        cardmarket_reverseHoloTrend: cmPrices.reverseHoloTrend,
        cardmarket_lowPriceExPlus: cmPrices.lowPriceExPlus,
        cardmarket_avg1: cmPrices.avg1,
        cardmarket_avg7: cmPrices.avg7,
        cardmarket_avg30: cmPrices.avg30,
        cardmarket_reverseHoloAvg1: cmPrices.reverseHoloAvg1,
        cardmarket_reverseHoloAvg7: cmPrices.reverseHoloAvg7,
        cardmarket_reverseHoloAvg30: cmPrices.reverseHoloAvg30
      };

      await pb.collection("prices").create(recordData);
      console.log(`Saved prices for card: ${cardEeid}`);
    } catch (err) {
      console.error("Error saving prices for card:", err.message);
      throw err;
    }
  };

  const fetchCardsByName = async (name) => {
    setLoading(true);
    setError("");
    let page = 1;
    let allCards = [];
    try {
      while (true) {
        const response = await axios.get(
          `https://api.pokemontcg.io/v2/cards?q=name:${name}*&page=${page}&pageSize=250`
        );
        allCards = [...allCards, ...response.data.data];
        if (response.data.data.length < 250) break;
        page++;
      }
      setCards(allCards);
      for (const card of allCards) {
        await savePricesToPocketBase(card);
      }
    } catch (err) {
      console.error(err);
      setError("Failed to fetch cards. Please try again.");
      setCards([]);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      fetchCardsByName(searchTerm);
    }
  };

  const handleCardSelect = (cardId) => {
    const found = cards.find((c) => c.id === cardId);
    setSelectedCard(found);
  };

  return (
    <div className="container mx-auto px-4">
      <form onSubmit={handleSubmit} className="flex items-center justify-center mt-5">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Enter Pokemon Name"
          className="shadow border rounded w-full py-2 px-3 text-gray-700 focus:outline-none"
        />
        <button
          type="submit"
          className="ml-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Search
        </button>
      </form>
      {loading && <p className="text-center mt-5">Loading...</p>}
      {error && <p className="text-center mt-5 text-red-500">{error}</p>}
      <div className="flex flex-col items-center mt-5">
        <p className="text-center">Total: {cards.length}</p>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-5">
          {cards.map((card) => (
            <div
              key={card.id}
              onClick={() => handleCardSelect(card.id)}
              className="cursor-pointer"
            >
              {card.name} - {card.number}/{card.set.printedTotal}
              <img src={card.images.small} alt={card.name} className="w-full h-auto" />
            </div>
          ))}
        </div>
        {selectedCard && (
          <div className="flex flex-row justify-between mt-5 max-w-4xl">
            <div>
              <h2 className="text-2xl font-bold">{selectedCard.name}</h2>
              <p><strong>Type:</strong> {selectedCard.types?.join(", ")}</p>
              <img
                src={selectedCard.images.large}
                alt={selectedCard.name}
                className="w-1/4 h-auto"
              />
              <p><strong>Set:</strong> {selectedCard.set.name}</p>
              <p><strong>Number:</strong> {selectedCard.number}</p>
              <p><strong>Artist:</strong> {selectedCard.artist}</p>
            </div>
            <div>
              {selectedCard.hp && (
                <p>
                  <strong>HP:</strong> {selectedCard.hp}
                </p>
              )}
              {selectedCard.attacks && (
                <div>
                  <h3 className="font-bold">Attacks:</h3>
                  {selectedCard.attacks.map((attack, index) => (
                    <div key={index} className="mb-2">
                      <p><strong>Name:</strong> {attack.name}</p>
                      <p><strong>Damage:</strong> {attack.damage}</p>
                      {attack.text && (
                        <p><strong>Text:</strong> {attack.text}</p>
                      )}
                    </div>
                  ))}
                </div>
              )}
              {selectedCard.weaknesses && (
                <div>
                  <h3 className="font-bold">Weaknesses:</h3>
                  {selectedCard.weaknesses.map((weakness, index) => (
                    <p key={index}>{weakness.type} {weakness.value}</p>
                  ))}
                </div>
              )}
              {selectedCard.resistances && (
                <div>
                  <h3 className="font-bold">Resistances:</h3>
                  {selectedCard.resistances.map((resistance, index) => (
                    <p key={index}>{resistance.type} {resistance.value}</p>
                  ))}
                </div>
              )}
              {selectedCard.abilities && (
                <div>
                  <h3 className="font-bold">Abilities:</h3>
                  {selectedCard.abilities.map((ability, index) => (
                    <p key={index}><strong>Name:</strong> {ability.name}</p>
                  ))}
                </div>
              )}
              {selectedCard.retreatCost && (
                <p>
                  <strong>Retreat Cost:</strong>{" "}
                  {selectedCard.retreatCost.join(", ")}
                </p>
              )}
              {selectedCard.rules && (
                <div>
                  <h3 className="font-bold">Rules:</h3>
                  {selectedCard.rules.map((rule, index) => (
                    <p key={index}>{rule}</p>
                  ))}
                </div>
              )}
              {selectedCard.subtypes && (
                <p>
                  <strong>Subtypes:</strong> {selectedCard.subtypes.join(", ")}
                </p>
              )}
              {selectedCard.set.series && (
                <p>
                  <strong>Series:</strong> {selectedCard.set.series}
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SyncPricesOnly;
