import React, { useState } from 'react';
import PocketBase from 'pocketbase';
import { useNavigate } from 'react-router-dom';
import Icon from '../../frontdesk/desk/UniversalIcons';

const pb = new PocketBase('https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/');

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const UniversalIcons = Icon;

    const handleLogin = async (e) => {
        e.preventDefault();
        const authData = await pb.collection('users').authWithPassword(email, password);
        localStorage.setItem('token', authData.token);
        localStorage.setItem('userId', authData.record.id);
        localStorage.setItem('role', authData.record.role);
        localStorage.setItem('email', authData.record.email);
        navigate('/');
    };

    return (
        <div className="flex items-center justify-center h-screen bg-footer-link">
            <form onSubmit={handleLogin} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <div className="flex justify-center mb-6 text-footer-link">
                    <UniversalIcons name="User" />
                </div>
                <h1 className="text-3xl text-center mb-6 text-footer-link">
                    Login
                </h1>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                        Email
                    </label>
                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                </div>
                <div className="mb-6">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                        Password
                    </label>
                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="password" type="password" placeholder="******************" value={password} onChange={(e) => setPassword(e.target.value)} required />
                </div>
                <div className="flex items-center justify-between">
                    <button className="bg-footer-link hover:bg-footer-title text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                        Login
                    </button>
                    <a className="inline-block align-baseline font-bold text-sm text-[#FF758F] hover:text-[#FF758F50]" href="/signup">
                        Register Now
                    </a>
                </div>
            </form>
        </div>
    );
};

export default Login;