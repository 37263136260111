import React, { useState, useEffect } from "react";
import PocketBase from "pocketbase";
import { motion, AnimatePresence } from "framer-motion";
import { useMeasure } from "react-use";
import Search from "./Search";
import MenuMobile from "./handheld/MenuMobile";

const pb = new PocketBase("https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/");

const Manage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [cards, setCards] = useState([]);
  const [stock, setStock] = useState({});
  const [selectedCardId, setSelectedCardId] = useState("");
  const [quantity, setQuantity] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [ref] = useMeasure();
  const [arrivals, setArrivals] = useState([]);
  const [arrival, setArrival] = useState("");
  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
  };
  const [selectedLang, setSelectedLang] = useState("");
  const [selectedType, setSelectedType] = useState(""); // Add this new state
  const [price, setPrice] = useState(""); // Add this new state
  const [selectedCondition, setSelectedCondition] = useState("");

  const conditions = [
    "Mint",
    "Near Mint",
    "Excellent",
    "Good",
    "Light Played",
    "Played",
    "Poor"
  ];

  const cardVariants = {
    hidden: { x: -100, opacity: 0 },
    visible: (custom) => ({
      x: 0,
      opacity: 1,
      transition: { delay: custom * 0.1 },
    }),
  };

  const typesIcons = {
    Psychic:
      "https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/api/files/j8ufu7icg4lcltu/r3ydhr36rscnq63/psychic_yTIDZSagKc.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTU4OTkyOTUsImlkIjoiMWZta3h3aGdxamFjdXMwIiwidHlwZSI6ImFkbWluIn0.GhjUiruxF7TGPQy47dd73yO2KmYQ4pIWO4Pzr3Wx6TU",
    Metal:
      "https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/api/files/j8ufu7icg4lcltu/84txqskfuaqtxnl/steal_ckCv5N68vT.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTU4OTkyOTUsImlkIjoiMWZta3h3aGdxamFjdXMwIiwidHlwZSI6ImFkbWluIn0.GhjUiruxF7TGPQy47dd73yO2KmYQ4pIWO4Pzr3Wx6TU",
    Lightning:
      "https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/api/files/j8ufu7icg4lcltu/qkcg6npmbclc3n4/lightning_yQctSjDojR.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTU4OTkyOTUsImlkIjoiMWZta3h3aGdxamFjdXMwIiwidHlwZSI6ImFkbWluIn0.GhjUiruxF7TGPQy47dd73yO2KmYQ4pIWO4Pzr3Wx6TU",
    Grass:
      "https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/api/files/j8ufu7icg4lcltu/ffq2nxcyu67msmr/grass_gMQzQ3wVpC.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTU4OTkyOTUsImlkIjoiMWZta3h3aGdxamFjdXMwIiwidHlwZSI6ImFkbWluIn0.GhjUiruxF7TGPQy47dd73yO2KmYQ4pIWO4Pzr3Wx6TU",
    Fire: "https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/api/files/j8ufu7icg4lcltu/9j0j0dn8yl9n5sl/fire_QSPMdt12en.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTU4OTkyOTUsImlkIjoiMWZta3h3aGdxamFjdXMwIiwidHlwZSI6ImFkbWluIn0.GhjUiruxF7TGPQy47dd73yO2KmYQ4pIWO4Pzr3Wx6TU",
    Fighting:
      "https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/api/files/j8ufu7icg4lcltu/udm6a6p06u6x1gs/fightning_L5YLcRZtXD.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTU4OTkyOTUsImlkIjoiMWZta3h3aGdxamFjdXMwIiwidHlwZSI6ImFkbWluIn0.GhjUiruxF7TGPQy47dd73yO2KmYQ4pIWO4Pzr3Wx6TU",
    Fairy:
      "https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/api/files/j8ufu7icg4lcltu/5a5ed0do84xcvq6/fairy_xnCg1vwDlu.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTU4OTkyOTUsImlkIjoiMWZta3h3aGdxamFjdXMwIiwidHlwZSI6ImFkbWluIn0.GhjUiruxF7TGPQy47dd73yO2KmYQ4pIWO4Pzr3Wx6TU",
    Dragon:
      "https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/api/files/j8ufu7icg4lcltu/sjje3dir71g64em/dragon_SdGEDgZb8Z.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTU4OTkyOTUsImlkIjoiMWZta3h3aGdxamFjdXMwIiwidHlwZSI6ImFkbWluIn0.GhjUiruxF7TGPQy47dd73yO2KmYQ4pIWO4Pzr3Wx6TU",
    Darkness:
      "https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/api/files/j8ufu7icg4lcltu/krtxm0qbtk9uw61/dark_F1ZKUj59dP.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTU4OTkyOTUsImlkIjoiMWZta3h3aGdxamFjdXMwIiwidHlwZSI6ImFkbWluIn0.GhjUiruxF7TGPQy47dd73yO2KmYQ4pIWO4Pzr3Wx6TU",
    Colorless:
      "https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/api/files/j8ufu7icg4lcltu/o5lm496jrdoduw4/colorless_fxD0Twa3jD.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTU4OTkyOTUsImlkIjoiMWZta3h3aGdxamFjdXMwIiwidHlwZSI6ImFkbWluIn0.GhjUiruxF7TGPQy47dd73yO2KmYQ4pIWO4Pzr3Wx6TU",
    Water:
      "https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/api/files/j8ufu7icg4lcltu/gms6ig2t9xfbyuh/water_m2EogghOm9.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTU4OTg5OTAsImlkIjoiMWZta3h3aGdxamFjdXMwIiwidHlwZSI6ImFkbWluIn0.OWumZliYtqF8McEmGlui8VopUnEApSKIzABgYt0YBUA",
  };

  const typesColorsHex = {
    Psychic: "#f8a5c2",
    Metal: "#8a8a8a",
    Lightning: "#f7d02c",
    Grass: "#8dd851",
    Fire: "#f9a552",
    Fighting: "#f3705a",
    Fairy: "#f0b8e8",
    Dragon: "#f16e57",
    Darkness: "#595761",
    Colorless: "#f0f0f0",
    Water: "#58abf6",
  };

  const habilityIcon = {
    hability:
      "https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/api/files/j8ufu7icg4lcltu/z7o446kec41ti4t/ability_big_xNNnOeaUWm.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTU4OTkyOTUsImlkIjoiMWZta3h3aGdxamFjdXMwIiwidHlwZSI6ImFkbWluIn0.GhjUiruxF7TGPQy47dd73yO2KmYQ4pIWO4Pzr3Wx6TU",
  };

  useEffect(() => {
    fetchStock();
    fetchArrivals();
  }, []);

  useEffect(() => {
    if (!searchTerm) {
      setCards([]);
      return;
    }
    fetchCards();
  }, [searchTerm]);

  const fetchStock = async () => {
    try {
      const result = await pb.collection("stock").getList();
      const stockMap = {};
      result.items.forEach(item => {
        if (!stockMap[item.card]) {
          stockMap[item.card] = [];
        }
        stockMap[item.card].push(item);
      });
      setStock(stockMap);
    } catch (error) {
      console.error("Error fetching stock:", error);
    }
  };

  const fetchCards = async () => {
    setLoading(true);
    try {
      const searchTerms = searchTerm
        .split(",")
        .map((term) => term.trim().toLowerCase());
      let resultItems = [];
      for (const term of searchTerms) {
        const filter = createSearchFilter(term);
        const result = await pb.collection("cards").getList(1, 70, {
          filter: filter,
        });
        resultItems = [...new Set([...resultItems, ...result.items])];
      }
      setCards(resultItems);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching cards:", error);
      setError("");
      setLoading(false);
    }
  };

  function createSearchFilter(searchTerm) {
    const [name, setptcgoCode, number] = searchTerm.split(/[\s,]+/);
    let filters = [];
    if (name && setptcgoCode) {
      filters.push(`name~"${name}"`);
      filters.push(`setptcgoCode~"${setptcgoCode}"`);
      if (number) {
        filters.push(`number="${number}"`); // Exact match for number
      }
    } else {
      const fields = ["name", "setptcgoCode", "number"];
      filters = fields.map((field) => `${field}~"${searchTerm}"`);
    }
    return filters.join(number ? " && " : " || ");
  }

  async function fetchArrivals() {
    try {
      const result = await pb.collection("arrivals").getList();
      setArrivals(result.items);
    } catch (error) {
      console.error("Error fetching arrivals:", error);
    }
  }

  const handleAddOrUpdateStock = async () => {
    if (!selectedCardId || quantity <= 0) return;

    const user = pb.authStore.model ? pb.authStore.model.id : null;

    try {
      // Always create a new entry
      await pb.collection("stock").create({
        card: selectedCardId,
        quantity: parseInt(quantity, 10),
        user: user,
        arrivalid: arrival,
        lang: selectedLang,
        type: selectedType,
        price: parseFloat(price) || 0,
        condition: selectedCondition,
      });

      fetchStock();
      setSelectedCardId("");
      setQuantity("");
      setSelectedLang("");
      setSelectedType("");
      setSelectedCondition("");
      setPrice("");
    } catch (error) {
      console.error("Error adding to stock:", error);
    }
  };

  const handleDeleteFromStock = async (stockItemId) => {
    const user = pb.authStore.model ? pb.authStore.model.id : null;

    try {
      await pb.collection("stock").delete(stockItemId);
      fetchStock();
    } catch (error) {
      console.error("Error deleting from stock:", error);
    }
  };

  return (
    <div className="flex flex-col container mx-auto p-4">
      <MenuMobile />
      <div className="flex flex-row justify-center items-center flex-wrap">
        <Search onSearch={handleSearch} />
      </div>
      {loading && <p>Loading...</p>}
      {error && <p className="text-red-500">{error}</p>}
      <h2 className="text-2xl p-2 text-justify">My Cards</h2>
      <div
        ref={ref}
        className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 py-2"
      >
        {Object.entries(stock).map(([cardId, stockItems]) => {
          const card = cards.find((c) => c.id === cardId);
          if (!card) return null;

          return (
            <div key={card.id} className="rounded-xl space-y-2">
              <div className="bg-[#FDE04780] p-4 rounded-xl">
                <div className="flex flex-col space-y-2">
                  <div className="flex justify-between items-center">
                    <div className="flex flex-col">
                      {card.name.slice(0, 10)}
                      {card.name.length > 10 ? "..." : ""}
                    </div>
                    <div className="flex flex-row">
                      {card.number}
                      <p className="text-gray-400">/{card.setprintedtotal}</p>
                    </div>
                  </div>

                  {/* Stock items list */}
                  {stockItems.map((item) => (
                    <div
                      key={item.id}
                      className="flex justify-between items-center p-2 bg-[#00000010] rounded-lg"
                    >
                      <div className="flex space-x-2">
                        <span className="bg-red-500 text-white px-2 py-1 rounded-full">
                          {item.quantity}
                        </span>
                        <span>{item.lang}</span>
                        <span>{item.type}</span>
                        <span>{item.condition?.slice(0, 4)}</span>
                        <span className="text-green-600 font-bold">
                          {item.price || '0'}€
                        </span>
                      </div>
                      <button
                        onClick={() => handleDeleteFromStock(item.id)}
                        className="text-red-500 hover:text-red-700 px-2"
                      >
                        Delete
                      </button>
                    </div>
                  ))}

                  {/* Add new entry form */}
                  {selectedCardId === card.id && (
                    <div className="flex flex-row justify-between items-center space-x-2 mt-2">
                      <input
                        type="string"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        className="bg-[#00000030] p-2 rounded-lg w-16"
                        placeholder="Qty"
                      />
                      <input
                        type="number"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        className="bg-[#00000030] p-2 rounded-lg w-16"
                        placeholder="Price"
                      />
                      <select
                        value={selectedLang}
                        onChange={(e) => setSelectedLang(e.target.value)}
                        className="bg-[#00000030] p-2 rounded-lg w-20"
                      >
                        <option value="">Lang</option>
                        <option value="es">es</option>
                        <option value="en">en</option>
                        <option value="pt">pt</option>
                      </select>
                      <select
                        value={selectedType}
                        onChange={(e) => setSelectedType(e.target.value)}
                        className="bg-[#00000030] p-2 rounded-lg w-24"
                      >
                        <option value="">Type</option>
                        <option value="normal">normal</option>
                        <option value="reverse">reverse</option>
                        <option value="league">league</option>
                        <option value="regional">regional</option>
                        <option value="staff">staff</option>
                        <option value="professor">professor</option>
                      </select>
                      <select
                        value={selectedCondition}
                        onChange={(e) => setSelectedCondition(e.target.value)}
                        className="bg-[#00000030] p-2 rounded-lg w-24"
                      >
                        <option value="">Condition</option>
                        {conditions.map(condition => (
                          <option key={condition} value={condition}>
                            {condition}
                          </option>
                        ))}
                      </select>
                      <button
                        className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-700"
                        onClick={handleAddOrUpdateStock}
                      >
                        Add
                      </button>
                    </div>
                  )}

                  {/* Add button */}
                  {selectedCardId !== card.id && (
                    <button
                      className="w-full mt-2 bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-700"
                      onClick={() => setSelectedCardId(card.id)}
                    >
                      Add Variant
                    </button>
                  )}
                </div>
              </div>

              {/* Card image and details */}
              <div className="flex flex-row justify-between items-center space-x-2">
                <img
                  src={card.imageslarge}
                  alt={card.name}
                  className="w-full h-auto rounded-xl hover:shadow-2xl transition duration-300"
                />
              </div>
              {card.hability && (
                <div className="flex flex-col items-left space-x-2 w-full p-4 bg-[#fbf06f60] rounded-xl shadow-lg">
                  <div className="flex flex-col items-left space-x-2 justify-between">
                    <div className="flex flex-row items-center justify-between space-x-2">
                      {card.hability && (
                        <img
                          src={habilityIcon.hability}
                          alt={card.hability}
                          className="w-16"
                        />
                      )}
                      {card.hability && (
                        <p className="font-bold text-xl text-red-500">
                          {card.hability}
                        </p>
                      )}
                    </div>
                    <div className="flex flex-row items-left space-x-2">
                      <p className="font-semibold text-md">
                        {card.hability_description}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {card.attack_one && (
                <div className="flex flex-col items-left space-x-2 w-full p-4 bg-[#fbf06f60] rounded-xl shadow-lg">
                  <div className="flex flex-col items-left space-x-2 justify-between">
                    <div className="flex flex-row items-left space-x-1 justify-between">
                      {card.attack_one_energy_cost_type_one && (
                        <img
                          src={
                            typesIcons[card.attack_one_energy_cost_type_one]
                          }
                          alt={card.attack_one_energy_cost_type_one}
                          className="w-7 h-7 rounded-full"
                        />
                      )}
                      {card.attack_one_energy_cost_type_two && (
                        <img
                          src={
                            typesIcons[card.attack_one_energy_cost_type_two]
                          }
                          alt={card.attack_one_energy_cost_type_two}
                          className="w-7 h-7 rounded-full"
                        />
                      )}
                      {card.attack_one_energy_cost_type_three && (
                        <img
                          src={
                            typesIcons[card.attack_one_energy_cost_type_three]
                          }
                          alt={card.attack_one_energy_cost_type_three}
                          className="w-7 h-7 rounded-full"
                        />
                      )}
                      {card.attack_one_energy_cost_type_four && (
                        <img
                          src={
                            typesIcons[card.attack_one_energy_cost_type_four]
                          }
                          alt={card.attack_one_energy_cost_type_four}
                          className="w-7 h-7 rounded-full"
                        />
                      )}
                      {card.attack_one_energy_cost_type_five && (
                        <img
                          src={
                            typesIcons[card.attack_one_energy_cost_type_five]
                          }
                          alt={card.attack_one_energy_cost_type_five}
                          className="w-7 h-7 rounded-full"
                        />
                      )}
                      <p className="font-bold text-xl">{card.attack_one}</p>
                      <p className="font-bold text-xl items text-red-500">
                        {card.attack_one_damage}
                      </p>
                    </div>
                  </div>
                  {card.attack_one_description && (
                    <div className="flex flex-col space-y-2 p-2 text-justify">
                      <div className="flex flex-row justify-between items-center space-x-2">
                        {card.attack_one_description && (
                          <p className="font-semibold text-left text-justify">
                            {card.attack_one_description}
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
              {card.attack_two && (
                <div className="flex flex-col items-left space-x-2 w-full p-4 bg-[#fbf06f60] rounded-xl shadow-lg">
                  <div className="flex flex-col items-left space-x-2 justify-between">
                    <div className="flex flex-row items-left space-x-1 justify-between">
                      {card.attack_two_energy_cost_type_one && (
                        <img
                          src={
                            typesIcons[card.attack_two_energy_cost_type_one]
                          }
                          alt={card.attack_two_energy_cost_type_one}
                          className="w-7 h-7 rounded-full"
                        />
                      )}
                      {card.attack_two_energy_cost_type_two && (
                        <img
                          src={
                            typesIcons[card.attack_two_energy_cost_type_two]
                          }
                          alt={card.attack_two_energy_cost_type_two}
                          className="w-7 h-7 rounded-full"
                        />
                      )}
                      {card.attack_two_energy_cost_type_three && (
                        <img
                          src={
                            typesIcons[card.attack_two_energy_cost_type_three]
                          }
                          alt={card.attack_two_energy_cost_type_three}
                          className="w-7 h-7 rounded-full"
                        />
                      )}
                      {card.attack_two_energy_cost_type_four && (
                        <img
                          src={
                            typesIcons[card.attack_two_energy_cost_type_four]
                          }
                          alt={card.attack_two_energy_cost_type_four}
                          className="w-7 h-7 rounded-full"
                        />
                      )}
                      {card.attack_two_energy_cost_type_five && (
                        <img
                          src={
                            typesIcons[card.attack_two_energy_cost_type_five]
                          }
                          alt={card.attack_two_energy_cost_type_five}
                          className="w-7 h-7 rounded-full"
                        />
                      )}
                      <p className="font-bold text-xl">{card.attack_two}</p>
                      <p className="font-bold text-xl items text-red-500">
                        {card.attack_two_damage}
                      </p>
                    </div>
                  </div>
                  {card.attack_two_description && (
                    <div className="flex flex-col space-y-2 p-2 text-justify">
                      <div className="flex flex-row justify-between items-center space-x-2">
                        {card.attack_two_description && (
                          <p className="font-semibold text-left text-justify">
                            {card.attack_two_description}
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
      <h2 className="text-2xl p-2 text-justify">Search Results</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 py-2">
        {cards.map((card) => {
          const isInStock = !!stock[card.id];
          if (isInStock) return null;
          return (
            <div
              key={card.id}
              className={`rounded-xl space-y-2 cursor-pointer bg-white shadow-lg ${selectedCardId === card.id ? "ring-2 ring-blue-500" : ""
                }`}
            >
              {/* Card Header */}
              <div className="bg-[#FDE04780] p-4 rounded-t-xl">
                <div className="flex justify-between items-center">
                  <div className="font-semibold">
                    {card.name.slice(0, 15)}
                    {card.name.length > 15 ? "..." : ""}
                  </div>
                  <div className="flex items-center space-x-2">
                    <span className="text-gray-600">{card.setptcgoCode}</span>
                    <span className="bg-gray-200 px-2 py-1 rounded-full text-sm">
                      {card.number}/{card.setprintedtotal}
                    </span>
                  </div>
                </div>
              </div>

              {/* Card Image */}
              <div className="px-4">
                <img
                  src={card.imagesmall}
                  alt={card.name}
                  className="w-full h-auto rounded-xl hover:shadow-xl transition duration-300"
                  onClick={() => {
                    setSelectedCardId(card.id);
                    setQuantity("1");
                  }}
                />
              </div>

              {/* Input Form - Only show when selected */}
              {selectedCardId === card.id && (
                <div className="p-4 bg-gray-50 rounded-b-xl">
                  <div className="grid grid-cols-2 gap-3">
                    {/* Quantity and Price row */}
                    <div className="col-span-2 flex space-x-3">
                      <div className="flex-1">
                        <label className="block text-sm font-medium text-gray-700 mb-1">Quantity</label>
                        <input
                          type="number"
                          value={quantity}
                          onChange={(e) => setQuantity(e.target.value)}
                          className="w-full p-3 bg-white border border-gray-300 rounded-lg text-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          placeholder="Qty"
                        />
                      </div>
                      <div className="flex-1">
                        <label className="block text-sm font-medium text-gray-700 mb-1">Price</label>
                        <input
                          type="number"
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                          className="w-full p-3 bg-white border border-gray-300 rounded-lg text-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          placeholder="€"
                        />
                      </div>
                    </div>

                    {/* Language Select */}
                    <div className="col-span-2">
                      <label className="block text-sm font-medium text-gray-700 mb-1">Language</label>
                      <select
                        value={selectedLang}
                        onChange={(e) => setSelectedLang(e.target.value)}
                        className="w-full p-3 bg-white border border-gray-300 rounded-lg text-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      >
                        <option value="">Select Language</option>
                        <option value="es">Spanish</option>
                        <option value="en">English</option>
                        <option value="pt">Portuguese</option>
                      </select>
                    </div>

                    {/* Type Select */}
                    <div className="col-span-1">
                      <label className="block text-sm font-medium text-gray-700 mb-1">Type</label>
                      <select
                        value={selectedType}
                        onChange={(e) => setSelectedType(e.target.value)}
                        className="w-full p-3 bg-white border border-gray-300 rounded-lg text-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      >
                        <option value="">Select Type</option>
                        <option value="normal">Normal</option>
                        <option value="reverse">Reverse</option>
                        <option value="league">League</option>
                        <option value="regional">Regional</option>
                        <option value="staff">Staff</option>
                        <option value="professor">Professor</option>
                      </select>
                    </div>

                    {/* Condition Select */}
                    <div className="col-span-1">
                      <label className="block text-sm font-medium text-gray-700 mb-1">Condition</label>
                      <select
                        value={selectedCondition}
                        onChange={(e) => setSelectedCondition(e.target.value)}
                        className="w-full p-3 bg-white border border-gray-300 rounded-lg text-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      >
                        <option value="">Select Condition</option>
                        {conditions.map(condition => (
                          <option key={condition} value={condition}>{condition}</option>
                        ))}
                      </select>
                    </div>

                    {/* Action Buttons */}
                    <div className="col-span-2 flex space-x-3 mt-3">
                      <button
                        className="flex-1 bg-blue-500 text-white p-3 rounded-lg hover:bg-blue-600 transition duration-200 font-medium text-lg"
                        onClick={handleAddOrUpdateStock}
                      >
                        Add to Stock
                      </button>
                      <button
                        className="flex-1 bg-green-500 text-white p-3 rounded-lg hover:bg-green-600 transition duration-200 font-medium text-lg"
                        onClick={() => setSearchTerm(card.name)}
                      >
                        Find Similar
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {/* Show Add button when not selected */}
              {selectedCardId !== card.id && (
                <div className="p-4">
                  <button
                    className="w-full bg-blue-500 text-white p-3 rounded-lg hover:bg-blue-600 transition duration-200 font-medium text-lg"
                    onClick={() => {
                      setSelectedCardId(card.id);
                      setQuantity("1");
                    }}
                  >
                    Add to Stock
                  </button>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Manage;
