import React, { useState, useEffect } from 'react';
import PocketBase from 'pocketbase';
import { motion } from "framer-motion"
import { useMeasure } from 'react-use';
import Search from './SearchMobile'; // adjust the path as necessary
import MenuMobile from "./MenuMobile";

const pb = new PocketBase('https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/');

const Manage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [cards, setCards] = useState([]);
  const [stock, setStock] = useState({});
  const [selectedCardId, setSelectedCardId] = useState('');
  const [quantity, setQuantity] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [stockItem] = useState({});
  const [MobileMenu, setMobileMenu] = useState(false);
  const [pricepurchase, setPricePurchase] = useState('');
  const [pricesale, setPriceSale] = useState('');
  const [ref] = useMeasure();
  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
  };

  const typesIcons = {
    Psychic: "https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/api/files/j8ufu7icg4lcltu/r3ydhr36rscnq63/psychic_ek0fkl11hX.png?token=",
    Metal: "https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/api/files/j8ufu7icg4lcltu/84txqskfuaqtxnl/metal_0YqbfzsCJh.png?token=",
    Lightning: "https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/api/files/j8ufu7icg4lcltu/qkcg6npmbclc3n4/lightning_yQ1l2vf2tY.png?token=",
    Grass: "https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/api/files/j8ufu7icg4lcltu/ffq2nxcyu67msmr/grass_vOn8344glV.png?token=",
    Fire: "https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/api/files/j8ufu7icg4lcltu/9j0j0dn8yl9n5sl/fire_fckUpuyUzQ.png?token=",
    Fighting: "https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/api/files/j8ufu7icg4lcltu/udm6a6p06u6x1gs/fighting_P78HVdyi5i.png?token=",
    Fairy: "https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/api/files/j8ufu7icg4lcltu/5a5ed0do84xcvq6/fairy_op9YsG0sku.png?token=",
    Dragon: "https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/api/files/j8ufu7icg4lcltu/sjje3dir71g64em/dragon_wYgWpBAn5V.png?token=",
    Darkness: "https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/api/files/j8ufu7icg4lcltu/krtxm0qbtk9uw61/darkness_w8CTmjEqVi.png?token=",
    Colorless: "https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/api/files/j8ufu7icg4lcltu/o5lm496jrdoduw4/colorless_5R3n1dM7Ei.png?token=",
    Water: "https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/api/files/j8ufu7icg4lcltu/gms6ig2t9xfbyuh/water_FhfQymj8Gw.png?token="
  };

  const habilityIcon = {
    hability: "https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/api/files/i9wekxntpec3m8l/qefgwulr212c2bw/ability_5JzfCVY0di.webp?token="
  };

  useEffect(() => {
    fetchStock();
  }, []);

  useEffect(() => {
    if (!searchTerm) {
      setCards([]);
      return;
    }
    fetchCards();
  }, [searchTerm]);

  /* a function that detects if user id = xbosxpdasfwpwwx, add arrivalid by default = sj20usv1zv9non2 */
  const mirrorid = () => {
    if (pb.authStore.model) {
      if (pb.authStore.model.id === 'xbosxpdasfwpwwx') {
        return 'sj20usv1zv9non2';
      }
      else {
        return "";
      }
    }
  };

  const fetchStock = async () => {
    try {
      const result = await pb.collection('sales').getList();
      const stockMap = result.items.reduce((acc, item) => {
        acc[item.card] = item; // Assume 'item.card' is the ID of the card
        return acc;
      }, {});
      setStock(stockMap);
    } catch (error) {
      console.error('Error fetching stock:', error);
    }
  };

  const fetchCards = async () => {
    setLoading(true);
    try {
      // Split search terms and trim each term for precise matching
      const searchTerms = searchTerm.split(',').map(term => term.trim().toLowerCase());
      let resultItems = [];
      for (const term of searchTerms) {
        // Use the term to create a more dynamic filter that can adapt to any card search
        const filter = createSearchFilter(term);
        const result = await pb.collection('cards').getList(1, 50, {
          filter: filter,
        });
        // Combine results from multiple searches into a single array without duplicates
        resultItems = [...new Set([...resultItems, ...result.items])];
      }
      setCards(resultItems);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching cards:', error);
      setError('');
      setLoading(false);
    }
  };

  function createSearchFilter(searchTerm) {
    // Split the searchTerm into name and setptcgoCode if it follows the "Name SetCode Number" format
    const [name, setptcgoCode, number] = searchTerm.split(/[\s,]+/);
    let filters = [];

    // Check if both name and setptcgoCode are present, then create a filter for them
    if (name && setptcgoCode) {
      filters.push(`name~"${name}"`);
      filters.push(`setptcgoCode~"${setptcgoCode}"`);
      if (number) {
        filters.push(`number="${number}"`); // Exact match for number
      }
    } else {
      // Fallback to a simpler search if the searchTerm doesn't split nicely into expected parts
      const fields = ['name', 'setptcgoCode', 'number'];
      filters = fields.map(field => `${field}~"${searchTerm}"`);
    }

    // Join the filters with 'AND' if searching for a specific card, otherwise 'OR' for broader search
    return filters.join(number ? ' && ' : ' || ');
  }


  const handleAddOrUpdateStock = async () => {
    if (!selectedCardId || quantity <= 0) return;

    const stockItem = stock[selectedCardId];
    const user = pb.authStore.model ? pb.authStore.model.id : null; // Get the user ID

    try {
      if (stockItem) {
        // Only allow the user who created the stock item to update it
        if (stockItem.user !== user) {
          throw new Error('You are not authorized to update this stock item.');
        }
        await pb.collection('sales').update(stockItem.id, { quantity: parseInt(quantity, 10) });
      } else {
        await pb.collection('sales').create({
          card: selectedCardId,
          quantity: parseInt(quantity, 10),
          user: user, // Add the user ID
          today: new Date().toISOString(),
          pricepurchase: pricepurchase,
          pricesale: pricesale,
        });
      }
      fetchStock();
      setSelectedCardId('');
      setQuantity('');
    } catch (error) {
      console.error('Error updating/adding to stock:', error);
    }
  };

  const handleDeleteFromStock = async (cardId) => {
    const stockItem = stock[cardId];
    const user = pb.authStore.model ? pb.authStore.model.id : null; // Get the user ID

    if (!stockItem) return;

    try {
      // Only allow the user who created the stock item to delete it
      if (stockItem.user !== user) {
        throw new Error('You are not authorized to delete this stock item.');
      }
      await pb.collection('sales').delete(stockItem.id);
      fetchStock();
    } catch (error) {
      console.error('Error deleting from stock:', error);
    }
  };

  return (
    <div className="flex flex-col container mx-auto p-4 pr-32">
      <div className="flex flex-row justify-center items-center flex-wrap">
        <MenuMobile />
        <Search onSearch={handleSearch} />
      </div>
      {loading && <p>Loading...</p>}
      {error && <p className="text-red-500">{error}</p>}
      {/* a section showing your cards in stock */}
      <h2 className="text-2xl p-2 text-justify">My Sales</h2>
      {/* compares the name from stock with the id from cards then returns the card */}
      <div ref={ref} className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 py-2">
        {Object.keys(stock).map((cardId) => {
          const stockItem = stock[cardId];
          const card = cards.find((c) => c.id === cardId);
          if (!card) return null;
          return (
            <motion.div
              whileHover={{ scale: 1.2 }}
              initial={{ scale: 1 }}
              key={card.id}
              drag // Enable drag
              dragConstraints={{
                top: 0,
                bottom: 0,
                left: 0,
                right: 0
              }}
              onDragEnd={(e, info) => {
                // Check if the card was dragged down
                if (info.offset.y > 0) {
                  // Delete the card
                  handleDeleteFromStock(card.id);
                }
              }}
              className={`rounded-xl space-y-2 cursor-pointer ${selectedCardId === card.id ? 'ring-2 ring-blue-500' : ''}`} onClick={() => { setSelectedCardId(card.id); setQuantity(stockItem.quantity); }}
            >
              <div className="bg-[#FDE047] p-4 rounded-xl">
                {selectedCardId === card.id ? (
                  <div className="flex flex-col space-y-2">
                    <div className="flex flex-row">
                      <input
                        type="string"
                        style={{ width: '100%' }}
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        className="bg-[#00000030] p-2 rounded-lg w-16 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Quantity"
                      />
                    </div>
                    <div className="flex flex-row justify-between items-center space-x-2">
                      <div className="flex flex-row items-center space-x-2">
                        <p className="font-semibold text-sm">Buy</p>
                        <input
                          type="string"
                          style={{ width: '100%' }}
                          value={pricepurchase}
                          onChange={(e) => setPricePurchase(e.target.value)}
                          className="bg-[#00000030] p-2 rounded-lg w-16 focus:outline-none focus:ring-2 focus:ring-blue-500"
                          placeholder="Price Purchase"
                        />
                        <p className="font-semibold text-sm">Sale</p>
                        <input
                          type="string"
                          style={{ width: '100%' }}
                          value={pricesale}
                          onChange={(e) => setPriceSale(e.target.value)}
                          className="bg-[#00000030] p-2 rounded-lg w-16 focus:outline-none focus:ring-2 focus:ring-blue-500"
                          placeholder="Price Sale"
                        />
                      </div>
                    </div>
                    <button
                      className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-700"
                      onClick={handleAddOrUpdateStock}
                    >
                      Update
                    </button>
                  </div>
                ) : (
                  <div className="flex flex-row justify-between items-center">
                    <div className="flex flex-col">
                      {card.name}
                    </div>
                    {/*   <div className="flex flex-col">
                      {card.setptcgoCode}
                    </div> */}
                    <div className="flex flex-col bg-red-500 text-white p-2 rounded-full w-8 h-8 flex justify-center items-center">
                      {stockItem.quantity}
                    </div>
                    <div className="flex flex-row">
                      {card.number}<p class="text-gray-400">/{card.setprintedtotal}</p>
                    </div>
                    <div className="flex flex-row">
                      {stockItem.pricepurchase}<p class="text-gray-400">/{stockItem.pricesale}</p>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex flex-row justify-between items-center space-x-2">
                <img src={card.imagesmall} alt={card.name} className="w-full h-auto rounded-xl hover:shadow-2xl transition duration-300" />
              </div>
              {card.hability && (
                <div className="flex flex-col items-left space-x-2 w-full p-4 bg-[#fbf06f90] rounded-xl shadow-lg">
                  <div className="flex flex-col items-left space-x-2 justify-between">
                    <div className="flex flex-row items-center space-x-1 justify-between">
                      {card.hability && <img src={habilityIcon.hability} alt={card.hability} className="w-16" />}
                      {card.hability && <p className="text-center font-bold text-[16px] text-red-500">{card.hability}</p>}
                    </div>
                    <div className="flex flex-row items-left space-x-2">
                      <p className="font-semibold text-sm">{card.hability_description}</p>
                    </div>
                  </div>
                </div>)}
              {/*
              {card.attack_one && (
                <div className="flex flex-col items-left space-x-2 w-full p-4 bg-[#fbf06f90] rounded-xl shadow-lg">
                  <div className="flex flex-col items-left justify-between">
                    <div className="flex flex-row items-center space-x-2 justify-between">
                      <p className="flex text-center font-bold text-[16px]">{card.attack_one}</p>
                      <p className="flex font-bold text-[16px] text-red-500">{card.attack_one_damage}</p>
                    </div>
                    <div className="flex flex-row items-center space-x-2">
                      {card.attack_one_energy_cost_type_one && <img src={typesIcons[card.attack_one_energy_cost_type_one]} alt={card.attack_one_energy_cost_type_one} className="w-5 h-5 rounded-full" />}
                      {card.attack_one_energy_cost_type_two && <img src={typesIcons[card.attack_one_energy_cost_type_two]} alt={card.attack_one_energy_cost_type_two} className="w-5 h-5 rounded-full" />}
                      {card.attack_one_energy_cost_type_three && <img src={typesIcons[card.attack_one_energy_cost_type_three]} alt={card.attack_one_energy_cost_type_three} className="w-5 h-5 rounded-full" />}
                      {card.attack_one_energy_cost_type_four && <img src={typesIcons[card.attack_one_energy_cost_type_four]} alt={card.attack_one_energy_cost_type_four} className="w-5 h-5 rounded-full" />}
                      {card.attack_one_energy_cost_type_five && <img src={typesIcons[card.attack_one_energy_cost_type_five]} alt={card.attack_one_energy_cost_type_five} className="w-5 h-5 rounded-full" />}
                    </div>
                  </div>
                  {card.attack_one_description && (
                    <div className="flex flex-col space-y-2 p-2 text-justify">
                      <div className="flex flex-row justify-between items-center space-x-2">
                        {card.attack_one_description && <p className="font-semibold text-sm">{card.attack_one_description}</p>}
                      </div>
                    </div>)}
                </div>)}
                */}
              {card.attack_one && (
                <div className="flex flex-col items-left space-x-2 w-full p-4 bg-[#fbf06f90] rounded-xl shadow-lg">
                  <div className="flex flex-col items-left space-x-2 justify-between">
                    <div className="flex flex-row items-center space-x-1 justify-between">
                      {card.attack_one_energy_cost_type_one && <img src={typesIcons[card.attack_one_energy_cost_type_one]} alt={card.attack_one_energy_cost_type_one} className="w-5 h-5 rounded-full" />}
                      {card.attack_one_energy_cost_type_two && <img src={typesIcons[card.attack_one_energy_cost_type_two]} alt={card.attack_one_energy_cost_type_two} className="w-5 h-5 rounded-full" />}
                      {card.attack_one_energy_cost_type_three && <img src={typesIcons[card.attack_one_energy_cost_type_three]} alt={card.attack_one_energy_cost_type_three} className="w-5 h-5 rounded-full" />}
                      {card.attack_one_energy_cost_type_four && <img src={typesIcons[card.attack_one_energy_cost_type_four]} alt={card.attack_one_energy_cost_type_four} className="w-5 h-5 rounded-full" />}
                      {card.attack_one_energy_cost_type_five && <img src={typesIcons[card.attack_one_energy_cost_type_five]} alt={card.attack_one_energy_cost_type_five} className="w-5 h-5 rounded-full" />}
                      <p className="text-center font-bold text-[16px]">{card.attack_one}</p>
                      <p className="font-bold text-[16px] text-red-500">{card.attack_one_damage}</p>
                    </div>
                  </div>
                  {card.attack_one_description && (
                    <div className="flex flex-col space-y-2 p-2 text-justify">
                      <div className="flex flex-row justify-between items-center space-x-2">
                        {card.attack_one_description && <p className="font-semibold text-sm">{card.attack_one_description}</p>}
                      </div>
                    </div>)}
                </div>)}
              {card.attack_two && (
                <div className="flex flex-col items-left space-x-2 w-full p-4 bg-[#fbf06f90] rounded-xl shadow-lg">
                  <div className="flex flex-col items-left space-x-2 justify-between">
                    <div className="flex flex-row items-center space-x-1 justify-between">
                      {card.attack_two_energy_cost_type_one && <img src={typesIcons[card.attack_two_energy_cost_type_one]} alt={card.attack_two_energy_cost_type_one} className="w-5 h-5 rounded-full" />}
                      {card.attack_two_energy_cost_type_two && <img src={typesIcons[card.attack_two_energy_cost_type_two]} alt={card.attack_two_energy_cost_type_two} className="w-5 h-5 rounded-full" />}
                      {card.attack_two_energy_cost_type_three && <img src={typesIcons[card.attack_two_energy_cost_type_three]} alt={card.attack_two_energy_cost_type_three} className="w-5 h-5 rounded-full" />}
                      {card.attack_two_energy_cost_type_four && <img src={typesIcons[card.attack_two_energy_cost_type_four]} alt={card.attack_two_energy_cost_type_four} className="w-5 h-5 rounded-full" />}
                      {card.attack_two_energy_cost_type_five && <img src={typesIcons[card.attack_two_energy_cost_type_five]} alt={card.attack_two_energy_cost_type_five} className="w-5 h-5 rounded-full" />}
                      <p className="text-center font-bold text-[16px]">{card.attack_two}</p>
                      <p className="font-bold text-[16px] text-red-500">{card.attack_two_damage}</p>
                    </div>
                  </div>
                  {card.attack_two_description && (
                    <div className="flex flex-col space-y-2 p-2 text-justify">
                      <div className="flex flex-row justify-between items-center space-x-2">
                        {card.attack_two_description && <p className="font-semibold text-sm">{card.attack_two_description}</p>}
                      </div>
                    </div>)}
                </div>)}
            </motion.div>
          );
        })}
      </div>
      {/* a section showing the cards that match the search term that are not in stock */}
      <h2 className="text-2xl p-2 text-justify">Search Results</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 py-2">
        {cards.map((card) => {
          const isInStock = !!stock[card.id];
          if (isInStock) return null;
          return (
            <motion.div
              whileHover={{ scale: 1.2 }}
              initial={{ scale: 1 }}
              key={card.id}
              drag // Enable drag
              dragConstraints={{
                top: 0,
                bottom: 0,
                left: 0,
                right: 0
              }}
              onDragEnd={(e, info) => {
                // Check if the card was dragged up
                if (info.offset.y < 0) {
                  // Increase the quantity
                  setQuantity(quantity + 1);
                  // Add the card to the stock
                  handleAddOrUpdateStock();
                }
              }}
              className={`rounded-xl space-y-2 cursor-pointer ${selectedCardId === card.id ? 'ring-2 ring-blue-500' : ''}`}
              onClick={() => { setSelectedCardId(card.id); setQuantity(stock[card.id]?.quantity || 1); }}
            >
              <div className="bg-[#FDE047] p-4 rounded-xl">
                {selectedCardId === card.id ? (
                  <div className="flex flex-col space-y-2">
                    <div className="flex flex-row justify-between items-center space-x-2">
                      <input
                        type="string"
                        style={{ width: '100%' }}
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        className="bg-[#00000030] p-2 rounded-lg w-16 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Quantity"
                      />
                      <button
                        className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-700"
                        onClick={handleAddOrUpdateStock}
                      >
                        Add
                      </button>
                      <button
                        className="bg-green-500 text-white p-2 rounded-lg hover:bg-green-700"
                        onClick={() => setSearchTerm(card.name)}
                      >
                        Similars
                      </button>
                    </div>
                    <div className="flex flex-row justify-between items-center space-x-2">
                      <p className="font-semibold text-sm">Buy</p>
                      <input
                        type="string"
                        style={{ width: '100%' }}
                        value={pricepurchase}
                        onChange={(e) => setPricePurchase(e.target.value)}
                        className="bg-[#00000030] p-2 rounded-lg w-16 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Price Purchase"
                      />
                      <p className="font-semibold text-sm">Sale</p>
                      <input
                        type="string"
                        style={{ width: '100%' }}
                        value={pricesale}
                        onChange={(e) => setPriceSale(e.target.value)}
                        className="bg-[#00000030] p-2 rounded-lg w-16 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Price Sale"
                      />
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-row justify-between items-center space-x-4">
                    <div className="flex flex-col">
                      {card.name}
                    </div>
                    <div className="flex flex-col">
                      {card.setptcgoCode}
                    </div>
                    <div className="flex flex-row">
                      {card.number}<p class="text-gray-500">/{card.setprintedtotal}</p>
                    </div>
                  </div>)}
              </div>
              <div className="flex flex-row justify-between items-center space-x-2">
                <img src={card.imagesmall} alt={card.name} className="w-full h-auto rounded-xl hover:shadow-2xl transition duration-300" />
              </div>
              {card.hability && (
                <div className="flex flex-col items-left space-x-2 w-full p-4 bg-[#fbf06f90] rounded-xl shadow-lg">
                  <div className="flex flex-col items-left space-x-2 justify-between">
                    <div className="flex flex-row items-center space-x-1 justify-between">
                      {card.hability && <img src={habilityIcon.hability} alt={card.hability} className="w-16" />}
                      {card.hability && <p className="text-center font-bold text-[16px] text-red-500">{card.hability}</p>}
                    </div>
                    <div className="flex flex-row items-left space-x-2">
                      <p className="font-semibold text-sm">{card.hability_description}</p>
                    </div>
                  </div>
                </div>)}
              {card.attack_one && (
                <div className="flex flex-col items-left space-x-2 w-full p-4 bg-[#fbf06f90] rounded-xl shadow-lg">
                  <div className="flex flex-col items-left space-x-2 justify-between">
                    <div className="flex flex-row items-center space-x-1 justify-between">
                      {card.attack_one_energy_cost_type_one && <img src={typesIcons[card.attack_one_energy_cost_type_one]} alt={card.attack_one_energy_cost_type_one} className="w-5 h-5 rounded-full" />}
                      {card.attack_one_energy_cost_type_two && <img src={typesIcons[card.attack_one_energy_cost_type_two]} alt={card.attack_one_energy_cost_type_two} className="w-5 h-5 rounded-full" />}
                      {card.attack_one_energy_cost_type_three && <img src={typesIcons[card.attack_one_energy_cost_type_three]} alt={card.attack_one_energy_cost_type_three} className="w-5 h-5 rounded-full" />}
                      {card.attack_one_energy_cost_type_four && <img src={typesIcons[card.attack_one_energy_cost_type_four]} alt={card.attack_one_energy_cost_type_four} className="w-5 h-5 rounded-full" />}
                      {card.attack_one_energy_cost_type_five && <img src={typesIcons[card.attack_one_energy_cost_type_five]} alt={card.attack_one_energy_cost_type_five} className="w-5 h-5 rounded-full" />}
                      <p className="text-center font-bold text-[16px]">{card.attack_one}</p>
                      <p className="font-bold text-[16px] text-red-500">{card.attack_one_damage}</p>
                    </div>
                  </div>
                  {card.attack_one_description && (
                    <div className="flex flex-col space-y-2 p-2 text-justify">
                      <div className="flex flex-row justify-between items-center space-x-2">
                        {card.attack_one_description && <p className="font-semibold text-sm">{card.attack_one_description}</p>}
                      </div>
                    </div>)}
                </div>)}
              {card.attack_two && (
                <div className="flex flex-col items-left space-x-2 w-full p-4 bg-[#fbf06f90] rounded-xl shadow-lg">
                  <div className="flex flex-col items-left space-x-2 justify-between">
                    <div className="flex flex-row items-center space-x-1 justify-between">
                      {card.attack_two_energy_cost_type_one && <img src={typesIcons[card.attack_two_energy_cost_type_one]} alt={card.attack_two_energy_cost_type_one} className="w-5 h-5 rounded-full" />}
                      {card.attack_two_energy_cost_type_two && <img src={typesIcons[card.attack_two_energy_cost_type_two]} alt={card.attack_two_energy_cost_type_two} className="w-5 h-5 rounded-full" />}
                      {card.attack_two_energy_cost_type_three && <img src={typesIcons[card.attack_two_energy_cost_type_three]} alt={card.attack_two_energy_cost_type_three} className="w-5 h-5 rounded-full" />}
                      {card.attack_two_energy_cost_type_four && <img src={typesIcons[card.attack_two_energy_cost_type_four]} alt={card.attack_two_energy_cost_type_four} className="w-5 h-5 rounded-full" />}
                      {card.attack_two_energy_cost_type_five && <img src={typesIcons[card.attack_two_energy_cost_type_five]} alt={card.attack_two_energy_cost_type_five} className="w-5 h-5 rounded-full" />}
                      <p className="text-center font-bold text-[16px]">{card.attack_two}</p>
                      <p className="font-bold text-[16px] text-red-500">{card.attack_two_damage}</p>
                    </div>
                  </div>
                  {card.attack_two_description && (
                    <div className="flex flex-col space-y-2 p-2 text-justify">
                      <div className="flex flex-row justify-between items-center space-x-2">
                        {card.attack_two_description && <p className="font-semibold text-sm">{card.attack_two_description}</p>}
                      </div>
                    </div>)}
                </div>)}
            </motion.div>
          );
        })}
      </div>
    </div >
  );
}

export default Manage;
