import React, { useState, useEffect } from 'react';
import PocketBase from 'pocketbase';

const pb = new PocketBase('https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/');

const BulkAddComponent = () => {
  const [bulkText, setBulkText] = useState('');
  const [cardsToReview, setCardsToReview] = useState([]);
  const [deckTitle, setDeckTitle] = useState('');
  const [user, setUser] = useState(null);

  useEffect(() => {
    const currentUser = pb.authStore.model;
    if (currentUser) {
      setUser(currentUser.id);
    }
  }, []);

  const parseBulkText = () => {
    const lines = bulkText.split('\n');
    const cardNames = lines.filter(line => line.trim() !== '').map(line => {
      const [quantity, ...rest] = line.split(' ');
      const name = rest.join(' ');
      return { quantity: parseInt(quantity.replace('x', '')), name };
    });
    fetchCards(cardNames);
  };

  const fetchCards = async (cards) => {
    const cardsWithImages = [];
    for (const card of cards) {
      const results = await pb.collection('cards').getList({
        filter: `name~"${card.name}"`,
        limit: 1,
      });
      if (results.items.length > 0) {
        cardsWithImages.push({ ...card, image: results.items[0].image, id: results.items[0].id });
      }
    }
    setCardsToReview(cardsWithImages);
  };

  const addToDeckAndStock = async () => {
    if (!deckTitle || cardsToReview.length === 0 || !user) return;
    const deck = await pb.collection('decks').create({
      title: deckTitle,
      user: user,
      cards: cardsToReview.map(card => card.id)
    });
    for (const card of cardsToReview) {
      await pb.collection('stock').create({
        card: card.id,
        quantity: card.quantity,
        user: user
      });
    }
    setBulkText('');
    setCardsToReview([]);
    setDeckTitle('');
  };

  return (
    <div>
      <h2>Add Cards in Bulk</h2>
      <textarea value={bulkText} onChange={(e) => setBulkText(e.target.value)} />
      <button onClick={parseBulkText}>Parse</button>
      <input type="text" placeholder="Deck Title" value={deckTitle} onChange={(e) => setDeckTitle(e.target.value)} />
      {cardsToReview.map((card, index) => (
        <div key={index}>
          <p>{card.quantity}x {card.name}</p>
          <img src={card.image} alt={card.name} />
        </div>
      ))}
      <button onClick={addToDeckAndStock}>Add to Deck and Stock</button>
    </div>
  );
};

export default BulkAddComponent;
